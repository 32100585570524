import { CrmLoadOneFirmPointSafetyStockResponse, RamateVerifyUpdateLastLiveHoursDatePayload } from './../swagger/backend';
import {
	CrmCreateAnnouncementRequest,
	RamateVerifyDeleteAnnouncementPayload,
	RamateVerifyUpdateAnnouncementPayload,
	CrmCRMFirmRegisterForSaleBombmyRequest,
	CrmCRMOrderBuildNoticeLetterRequest,
	CrmCRMOrderCustomerMessageNoticeLetterRequest,
	CrmCRMOrderFirmMessageNoticeLetterRequest,
	CrmCRMOrderPaymentCompleteNoticeLetterRequest,
	CrmRamateAccountAutoLoginRequest,
	CrmRamateAccountLoginRequest,
	CrmRamateAccountRegisterRequest,
	RamateVerifyRamateUpdateAccountInfoPayload,
	RamateVerifyUpdateFirmAuthorityPayload,
	RamateVerifyUpdateFirmLiveHoursPayload,
	RamateVerifyUpdateFirmSmsPointPayload,
} from '../swagger/backend';
import { backendApi } from './base';

export const getRamateFirmList = async () => {
	const { data } = await backendApi.ramateVerify.ramateVerifyFirmList();
	// console.log(data);
	return data;
};

export const getFirmSmsPointLog = async () => {
	const { data } = await backendApi.ramateVerify.ramateVerifyFirmSmsPointLog();
	// console.log(data);
	return data;
};

export const updateFirmAuthority = async (firmId: string, req: RamateVerifyUpdateFirmAuthorityPayload) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyUpdateFirmAuthority(firmId, req);
	// console.log(data);
	return data;
};

export const getSingleFirmSmsPointLog = async (firmId: string) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyLoanOneFirmSmsPointLog(firmId);
	// console.log(data);
	return data;
};

export const getSingleFirmLiveHoursLog = async (firmId: string) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyLoanOneFirmLiveHoursLog(firmId);
	// console.log(data);
	return data;
};

export const ramateVerifySendSms = async (firmId: string, smsContent: string, receiveUserList: string[], updateUser: string) => {
	const { data } = await backendApi.ramateVerify.ramateVerifySendSms(firmId, {
		smsContent: smsContent,
		receiveUserList: receiveUserList,
		updateUser: updateUser,
	});
	// console.log(data);
	return data;
};

export const updateFirmSmsPoint = async (firmId: string, req: RamateVerifyUpdateFirmSmsPointPayload) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyUpdateFirmSmsPoint(firmId, req);
	// console.log(data);
	return data;
};

export const updateFirmLiveHours = async (firmId: string, req: RamateVerifyUpdateFirmLiveHoursPayload) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyUpdateFirmLiveHours(firmId, req);
	// console.log(data);
	return data;
};

export const updateFirmLastLiveHoursDate = async (firmId: string, req: RamateVerifyUpdateLastLiveHoursDatePayload) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyUpdateLastLiveHoursDate(firmId, req);
	// console.log(data);
	return data;
};

/** 管理員登入註冊 */

export const adminLogin = async (req: CrmRamateAccountLoginRequest) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyRamateAccountLogin(req);
	// console.log(data);
	return data;
};

export const adminRegister = async (req: CrmRamateAccountRegisterRequest) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyRamateAccountRegister(req);
	// console.log(data);
	return data;
};

export const adminAutoLogin = async (req: CrmRamateAccountAutoLoginRequest) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyRamateAccountAutoLogin(req);
	// console.log(data);
	return data;
};

/** 寄信 */

export const firmRegisterForSaleBombmy = async (req: CrmCRMFirmRegisterForSaleBombmyRequest) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyCrmFirmRegisterForSaleBombmy(req);
	// console.log(data);
	return data;
};

export const orderBuildNoticeLetter = async (req: CrmCRMOrderBuildNoticeLetterRequest) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyCrmOrderBuildNoticeLetter(req);
	// console.log(data);
	return data;
};

export const orderCustomerMessageNoticeLetter = async (req: CrmCRMOrderCustomerMessageNoticeLetterRequest) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyCrmOrderCustomerMessageNoticeLetter(req);
	// console.log(data);
	return data;
};

export const orderFirmMessageNoticeLetter = async (req: CrmCRMOrderFirmMessageNoticeLetterRequest) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyCrmOrderFirmMessageNoticeLetter(req);
	// console.log(data);
	return data;
};

export const orderPaymentCompleteNoticeLetter = async (req: CrmCRMOrderPaymentCompleteNoticeLetterRequest) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyCrmOrderPaymentCompleteNoticeLetter(req);
	// console.log(data);
	return data;
};

export const adminCheckUsage = async (date: string) => {
	const { data } = await backendApi.ramateVerify.ramateVerifySearchBombmyLivePeriod({
		searchDate: date,
	});
	// console.log(data);
	return data;
};

export const getAdminInfo = async (accountId: string) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyRamateLoadAccountInfo(accountId);
	// console.log(data);
	return data;
};

export const updateAdminInfo = async (accountId: string, req: RamateVerifyRamateUpdateAccountInfoPayload) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyRamateUpdateAccountInfo(accountId, req);
	// console.log(data);
	return data;
};

export const loadAnnouncement = async () => {
	const { data } = await backendApi.ramateVerify.ramateVerifyLoadAnnouncementList();
	// console.log(data);
	return data;
};

export const createAnnouncement = async (req: CrmCreateAnnouncementRequest) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyCreateAnnouncement(req);
	// console.log(data);
	return data;
};

export const updateAnnouncement = async (announcementId: string, req: RamateVerifyUpdateAnnouncementPayload) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyUpdateAnnouncement(announcementId, req);
	// console.log(data);
	return data;
};

export const deleteAnnouncement = async (announcementId: string, req: RamateVerifyDeleteAnnouncementPayload) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyDeleteAnnouncement(announcementId, req);
	// console.log(data);
	return data;
};

export const loadOneAnnouncement = async (announcementId: string) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyLoadOneAnnouncement(announcementId);
	// console.log(data);
	return data;
};

export const loadAnnouncementPublic = async (service: number) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyLoadAnnouncementListPublic({ service: service });
	// console.log(data);
	return data;
};

export const loadPointSafetyStock = async (firmId: string) => {
	const { data } = await backendApi.firmInfo.firmInfoLoadOneFirmPointSafetyStock(firmId);
	// console.log(data);
	return data;
};

export const updatePointSafetyStock = async (firmId: string, pointSafetyStock: number) => {
	const { data } = await backendApi.firmInfo.firmInfoUpdatePointSafetyStock(firmId, { pointSafetyStock: pointSafetyStock });
	// console.log(data);
	return data;
};

export const getLiveDetailInfo = async (liveId: string) => {
	const { data } = await backendApi.ramateVerify.ramateVerifyLoadLiveDetailInfo({ liveId: liveId });
	return data;
};

import { TColor } from '../../type/color-type';
import { TIcons } from '../../type/icons-type';

export interface IEventStatus {
	[key: string]: { name: string; color: TColor };
}

export interface PaymentMethodProp {
	[key: string]: { name: string; color: TColor; paymentMethod: string };
}

export interface OrderEventStatus {
	[key: string]: { name: string; color: TColor; icon: TIcons };
}

export interface SimpleStatus {
	[key: string]: string;
}

/**
 *
 * order_status
 * 0:已下單 1:已填個人資料 2:已付款3:已完成 4:付款失敗5:已取消
 * 2022 11 01更改為
 * 0:等待確認 1:處理中 2:已完成 3:已取消
 *
 * invoice_status
 * 0:未開立1:已開立2:註銷
 *
 * delivery_status
 * 0:等待配送 1:備貨中2:已出貨3:配送中4:送達取貨點5:已取貨6:異常7:換貨/退貨8:延遲出貨
 *
 * payment_status
 * 0:等待付款 1:待確認付款2:已付款 3:貨到付款 4:已退款 5:付款失敗 6:已取號
 *
 * payment_method
 * 0:網路atm 1:虛擬atm 2:信用卡(一次付清)  3:信用卡(分期 3) 4:信用卡(分期 6)
 * 5:信用卡(分期 12) 6:信用卡(分期 24)
 *
 *
 *
 *
 */

export const DELIVERY_STATUS: IEventStatus = {
	0: { name: '等待出貨', color: 'brand-two' },
	1: { name: '備貨中', color: 'brand' },
	2: { name: '已出貨', color: 'success' },
	3: { name: '配送中', color: 'primary' },
	4: { name: '送達取貨地', color: 'secondary' },
	5: { name: '已取貨', color: 'info' },
	6: { name: '異常', color: 'danger' },
	7: { name: '換貨/退貨', color: 'dark' },
	8: { name: '延遲出貨', color: 'warning' },
};

export const PAYMENT_STATUS: IEventStatus = {
	0: { name: '等待付款', color: 'brand-two' },
	1: { name: '待確認付款', color: 'brand' },
	2: { name: '付款成功', color: 'success' },
	3: { name: '貨到付款', color: 'primary' },
	4: { name: '已退款', color: 'dark' },
	5: { name: '付款失敗', color: 'danger' },
	6: { name: '已取號', color: 'info' },
};

export const PAYMENT_METHOD: PaymentMethodProp = {
	0: { name: '銀行轉帳', color: 'light', paymentMethod: '0' },
	1: { name: '虛擬匯款帳戶', color: 'dark', paymentMethod: '1' },
	2: { name: '信用卡(一次付清)', color: 'success', paymentMethod: '2' },
	3: { name: '信用卡(分3期)', color: 'success', paymentMethod: '3' },
	4: { name: '信用卡(分6期)', color: 'success', paymentMethod: '4' },
	5: { name: '信用卡(分12期)', color: 'success', paymentMethod: '5' },
	6: { name: '信用卡(分24期)', color: 'success', paymentMethod: '6' },
	7: { name: '零卡分期(分3期)', color: 'light', paymentMethod: '7' },
	8: { name: '零卡分期(分6期) ', color: 'light', paymentMethod: '8' },
	9: { name: '零卡分期(分12期)', color: 'light', paymentMethod: '9' },
	10: { name: '零卡分期(分24期)', color: 'light', paymentMethod: '10' },
	11: { name: '定期定額', color: 'dark', paymentMethod: '11' },
	12: { name: '信用卡(分18期)', color: 'success', paymentMethod: '12' },
	13: { name: 'LinePay', color: 'success', paymentMethod: '13' },
};

export const INVOICE_STATUS: IEventStatus = {
	0: { name: '未開立', color: 'danger' },
	1: { name: '已開立', color: 'success' },
	2: { name: '註銷', color: 'dark' },
	3: { name: '訂單已退款', color: 'danger' },
};

export const ORDER_STATUS: OrderEventStatus = {
	0: { name: '等待確認', color: 'light', icon: 'AccessTime' },
	1: { name: '處理中', color: 'primary', icon: 'DirectionsRun' },
	2: { name: '已完成', color: 'success', icon: 'CheckCircle' },
	3: { name: '已取消', color: 'dark', icon: 'Cancel' },
	4: { name: '手動匯入', color: 'secondary', icon: 'Visibility' },
};

// export const DELIVERY_TYPE: IEventStatus = {
// 	0: { name: '不需運送', color: 'light' },
// };

export const INVOICE_TYPE: SimpleStatus = {
	'0': '個人',
	'1': '公司',
	'2': '捐贈',
};

export const INVOICE_ISSUE_TYPE: SimpleStatus = {
	'0': '紙本',
	'1': '電子發票',
};

export const AFFILIATE_STATUS: OrderEventStatus = {
	0: { name: '無權限', color: 'danger', icon: 'Close' },
	1: { name: '僅檢視', color: 'secondary', icon: 'Visibility' },
	2: { name: '可編輯', color: 'success', icon: 'Check' },
};

export const BOMBMY_STATUS: OrderEventStatus = {
	false: { name: '關閉', color: 'light', icon: 'Close' },
	true: { name: '啟用', color: 'success', icon: 'Check' },
};

export const ASSOCIATION_STATUS: OrderEventStatus = {
	false: { name: '關閉', color: 'light', icon: 'Close' },
	true: { name: '啟用', color: 'success', icon: 'Check' },
};

export const CASH_FLOW_TYPE: SimpleStatus = {
	0: '綠界',
	1: '藍新',
	2: '歐付寶',
	3: '銀角零卡',
	4: 'paypal',
	5: '統一支付',
	6: '支付連',
	7: '紅陽',
	8: '第一融資',
	9: 'FunPoint',
	10: '立吉富',
	11: 'Stripe',
	12: 'LinePay',
	99: '無金流',
};

export const INVOICE_CARRIER_TYPE: SimpleStatus = {
	'': '無載具',
	'1': '電子發票載具',
	'2': '自然人憑證',
	'3': '手機條碼',
};

// export const DELIVERY_STATUS: IEventStatus = {
// 	WAIT: { name: '等待出貨', color: 'light' },
// 	PERPARE: { name: '備貨中', color: 'warning' },
// 	SEND: { name: '已出貨', color: 'success' },
// 	POST: { name: '配送中', color: 'primary' },
// 	ALIVE: { name: '送達取貨地', color: 'secondary' },
// 	GET: { name: '已取貨', color: 'success' },
// 	RETURNED: { name: '已退貨', color: 'dark' },
// 	ERROR: { name: '異常', color: 'danger' },
// };

// export const PAYMENT_STATUS: IEventStatus = {
// 	CANCELED: { name: '等待付款', color: 'light' },
// 	PENDING: { name: '待確認付款', color: 'warning' },
// 	APPROVED: { name: '付款成功', color: 'success' },
// 	REJECTED: { name: '已退款', color: 'dark' },
// 	DELIVERY: { name: '貨到付款', color: 'info' },
// };

/**
 * 物流方式類型(
 *        0:宅配(台灣本島)
 *        1:宅配(離島)
 *        2:宅配(海外)
 *        3:其他(海外)
 *        4:郵寄
 *        5:門市自取
 *        6:7-11超商取貨
 *        7:全家超商取貨
 *        8:萊爾富超商取貨
 *        9:不需運送(虛擬產品)
 */

export const DELIVERY_TYPES: SimpleStatus = {
	'0': '宅配(台灣本島)',
	'1': '宅配(離島)',
	'2': '宅配(海外)',
	'3': '其他(海外)',
	'4': '郵寄',
	'5': '門市自取',
	'6': '7-11超商取貨',
	'7': '全家超商取貨',
	'8': '萊爾富超商取貨',
	'9': '不需運送',
};

export type ThirdPartyModalType =
	| 'ecPay'
	| 'oPay'
	| 'newebPay'
	| 'zingala'
	| 'pchomePay'
	| 'ezPay'
	| 'sunPay'
	| 'firstPay'
	| 'funPoint'
	| 'payuni'
	| 'stripe'
	| 'paypal'
	| 'pifc'
	| 'paynow'
	| 'linepay'
	| '';

export const ANNOUNCEMENTS_TYPE: IEventStatus = {
	FIX: { color: 'danger', name: '錯誤修正' },
	ANNOUNCEMENT: { color: 'info', name: '通知' },
	NEW: { color: 'warning', name: '新功能' },
	IMPROVEMENT: { color: 'success', name: '改進' },
	OTHER: { color: 'dark', name: '其他' },
};

export const ANNOUNCEMENT_SERVICE_TYPE: SimpleStatus = {
	'0': '全部',
	'1': '幫賣',
	'2': 'CRM',
};

import {
	PurchaseATMPaidNotifyReq,
	PurchaseCreateOrderLogCustomerReq,
	PurchaseCreatePaymentReq,
	PurchaseRefundPaymentReq,
	PurchaseUpdateInvoiceInfoReq,
} from './../swagger/thirdParty';
import { thirdPartyApi } from './base';

export const getFrontOrder = async (orderId: string) => {
	const { data } = await thirdPartyApi.purchase.purchaseGetOrderInfo({ orderId: orderId });
	// console.log(data);
	return data;
};

export const changeFrontPaymentMethod = async (orderId: string, paymentMethod: string, updateUser: string, platformType: string) => {
	const { data } = await thirdPartyApi.purchase.purchaseUpdatePaymentMethod({
		orderId: orderId,
		paymentMethod: paymentMethod,
		updateUser: updateUser,
		platformType: platformType,
	});
	// console.log(data);
	return data;
};

export const getFirmPayMethod = async (firmId: string, bombMySignupId: string, platformType: string) => {
	const { data } = await thirdPartyApi.purchase.purchaseGetFirmPayMethod({
		firmId: firmId,
		bombMySignupId: bombMySignupId,
		platformType: platformType,
	});
	// console.log('getFirmPayMethod', data);
	return data;
};

export const frontCreatePayment = async (req: PurchaseCreatePaymentReq) => {
	const { data } = await thirdPartyApi.purchase.purchaseCreatePayment(req);
	// console.log(data);
	return data;
};

export const createOrderLogCustomer = async (req: PurchaseCreateOrderLogCustomerReq) => {
	const { data } = await thirdPartyApi.purchase.purchaseCreateOrderLogCustomer(req);
	// console.log(data);
	return data;
};

export const frontRefundPayment = async (req: PurchaseRefundPaymentReq) => {
	const { data } = await thirdPartyApi.purchase.crmRefundPayment(req);
	// console.log(data);
	return data;
};

export const frontUpdateInvoiceInfo = async (req: PurchaseUpdateInvoiceInfoReq) => {
	const { data } = await thirdPartyApi.purchase.purchaseUpdateInvoiceInfo(req);
	// console.log(data);
	return data;
};

export const atmPaidNotify = async (req: PurchaseATMPaidNotifyReq) => {
	const { data } = await thirdPartyApi.purchase.purchaseAtmPaidNotify(req);
	// console.log(data);
	return data;
};

export const getFirmInvoiceMethod = async (firmId: string) => {
	const { data } = await thirdPartyApi.purchase.cashFlowGetFirmInvoiceMethod({ firmId: firmId });
	// console.log(data);
	return data;
};

export const checkNotice = async (orderId: string) => {
	const { data } = await thirdPartyApi.purchase.purchaseCheckNotice({ orderId: orderId });
	// console.log(data);
	return data;
};

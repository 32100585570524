import React, { useCallback, useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import { useTranslation } from 'react-i18next';
import * as API from '../../../api/account';
import { useDispatch } from 'react-redux';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
import Icon from '../../../components/icon/Icon';
import showNotification from '../../../components/extras/showNotification';
import { useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: React.FC<ILoginProps> = ({ isSignUp }) => {
	const { setUser } = useContext(AuthContext);
	const token = useSelector((state: RootState) => state.user.userInfo.token);
	const { t } = useTranslation(['auth']);
	const dispatch = useDispatch();
	const { darkModeStatus } = useDarkMode();
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/orders'), [navigate]);

	useEffect(() => {
		if (token !== '') {
			navigate('/orders');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = '請輸入帳號';
			}

			if (!values.loginPassword) {
				errors.loginPassword = '請輸入密碼';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			const deviceId = await getCurrentBrowserFingerPrint();
			const account = {
				account: values.loginUsername,
				password: values.loginPassword,
				platformType: '0',
				deviceId: deviceId.toString(),
			};
			API.login(account)
				.then((res) => {
					let platform = '';
					if (res.data.platformAuthority?.bombcourse) {
						platform = '1'; //沒有bombmy權限時預設以幫開課為主
					}
					if (res.data.platformAuthority?.bombmy) {
						platform = '0'; //預設以幫賣為主
					}
					if (setUser) {
						const storageData = {
							firmId: res.data?.firmInfo?.firmId,
							userId: res.data?.userInfo?.userId,
							token: res.data?.userInfo?.token,
							deviceId: account.deviceId,
							csrf: res.headers.get('Grpc-Metadata-Csrf-Token'),
							platform: platform,
						};
						setUser(storageData);
					}
					dispatch({
						type: 'FIRM_LOGIN',
						payload: { ...res.data, deviceId: account.deviceId, csrf: res.headers.get('Grpc-Metadata-Csrf-Token'), platform: platform },
					});
					handleOnClick();
				})
				.catch((err) => {
					if (err.status === 503 || err.error.code === 14) {
						formik.handleSubmit();
					} else {
						formik.setFieldError('loginPassword', err.error.message);
					}
				});
		},
	});

	const signupFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			account: '',
			password: '',
			confirmPassword: '',
			email: '',
		},
		validate: (values) => {
			const errors: { email?: string; confirmPassword?: string } = {};

			if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
				errors.email = '請輸入正確的Email';
			}

			if (!values.confirmPassword) {
				errors.confirmPassword = '請輸入確認密碼';
			}

			if (values.confirmPassword !== values.password) {
				errors.confirmPassword = '確認密碼與密碼不相符';
			}

			return errors;
		},
		validateOnChange: true,
		onSubmit: (values) => {
			// console.log(values);
			const account = {
				account: values.account,
				password: values.password,
				email: values.email,
			};
			API.register(account)
				.then((res) => {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>註冊成功！</span>
						</span>,
						'',
						'success',
					);
				})
				.catch((err) => {
					showNotification('註冊發生錯誤', err.error.message, 'danger');
				});
		},
	});

	const LoginHeader: React.FC<ILoginHeaderProps> = ({ isNewUser }) => {
		if (isNewUser) {
			return (
				<>
					<div className='text-center h1 fw-bold mt-5'>{t('Sign Up Title')}</div>
					<div className='text-center h4 text-muted mb-5'>{t('Sign Up Content')}</div>
				</>
			);
		}
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>{t('Login Title')}</div>
				<div className='text-center h4 text-muted mb-5'>{t('Login Content')}</div>
			</>
		);
	};

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? t('Sign Up') : t('Login')}
			className={classNames({ 'bg-warning': !singUpStatus, 'bg-info': singUpStatus })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames('text-decoration-none  fw-bold display-2', {
											'text-dark': !darkModeStatus,
											'text-light': darkModeStatus,
										})}>
										<Logo width={300} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSingUpStatus(false);
												}}>
												{t('Login')}
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSingUpStatus(true);
												}}>
												{t('Sign Up')}
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								<form className='row g-4'>
									{singUpStatus ? (
										<>
											<div className='col-12'>
												<FormGroup id='account' isFloating label='帳號'>
													<Input type='text' value={signupFormik.values.account} onChange={signupFormik.handleChange} autoComplete='username' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup id='email' isFloating label='Email'>
													<Input
														value={signupFormik.values.email}
														onChange={signupFormik.handleChange}
														autoComplete='email'
														invalidFeedback={signupFormik.errors.email}
														onBlur={signupFormik.handleBlur}
														isTouched={signupFormik.touched.email}
														isValid={signupFormik.isValid}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup id='password' isFloating label='密碼'>
													<Input type='password' value={signupFormik.values.password} onChange={signupFormik.handleChange} autoComplete='password' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup id='confirmPassword' isFloating label='確認密碼'>
													<Input
														type='password'
														value={signupFormik.values.confirmPassword}
														onChange={signupFormik.handleChange}
														invalidFeedback={signupFormik.errors.confirmPassword}
														onBlur={signupFormik.handleBlur}
														isTouched={signupFormik.touched.confirmPassword}
														isValid={signupFormik.isValid}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button color='info' className='w-100 py-3' onClick={signupFormik.handleSubmit}>
													{t('Sign Up')}
												</Button>
											</div>
										</>
									) : (
										<>
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label={t('username')}
													// className={classNames({
													// 	'd-none': signInPassword,
													// })}
												>
													<Input
														autoComplete='username'
														value={formik.values.loginUsername}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={formik.errors.loginUsername}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
												{/* {signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>Hi, {formik.values.loginUsername}.</div>
												)} */}
												<FormGroup
													className='mt-3'
													id='loginPassword'
													isFloating
													label={t('password')}
													// className={classNames({
													// 	'd-none': !signInPassword,
													// })}
												>
													<Input
														type='password'
														autoComplete='current-password'
														value={formik.values.loginPassword}
														isTouched={formik.touched.loginPassword}
														invalidFeedback={formik.errors.loginPassword}
														validFeedback='Looks good!'
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												{/* {!signInPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'
														isDisable={!formik.values.loginUsername}
														onClick={handleContinue}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														{t('Continue')}
													</Button>
												) : (
													<Button
														color='warning'
														className='w-100 py-3'
														onClick={formik.handleSubmit}>
														{t('Login')}
													</Button>
												)} */}
												<Button color='warning' type='submit' className='w-100 py-3' onClick={formik.handleSubmit}>
													{t('Login')}
												</Button>
											</div>
										</>
									)}

									{/* BEGIN :: Social Login */}
									{/* {!signInPassword && (
										<>
											<div className='col-12 mt-3 text-center text-muted'>OR</div>
											<div className='col-12 mt-3'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomApple'
													onClick={handleOnClick}>
													{t('Sign in with Apple')}
												</Button>
											</div>
											<div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleOnClick}>
													{t('Continue with Google')}
												</Button>
											</div>
										</>
									)} */}
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								{t('Privacy policy')}
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								{t('Terms of use')}
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
